.bg-home {

    border-radius: 1rem;
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;

}


.icon-hover:hover {
    color: #f15cfa; /* Desired hover color */
  }
  

