@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

body {
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #93B1A6;
  align-items: center;
  justify-content: center;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Stile generale per la barra di scorrimento */
::-webkit-scrollbar {
  width: 12px;  /* Larghezza della barra di scorrimento verticale */
  height: 12px;  /* Altezza della barra di scorrimento orizzontale */
}

/* Tracciato della barra di scorrimento */
::-webkit-scrollbar-track {
  background: #0e0e0e;  /* Colore di sfondo */
  border-radius: 10px;  /* Raggio del bordo */
}

/* Maniglia della barra di scorrimento */
::-webkit-scrollbar-thumb {
  background: #ff5c3f;  /* Colore della maniglia */
  border-radius: 10px;  /* Raggio del bordo */
}

/* Maniglia della barra di scorrimento durante il passaggio del mouse */
::-webkit-scrollbar-thumb:hover {
  background: #ff704d;  /* Colore della maniglia quando si passa con il mouse */
}

/* Maniglia della barra di scorrimento quando si clicca */
::-webkit-scrollbar-thumb:active {
  background: #ff451c;  /* Colore della maniglia quando si clicca */
}

