a/* Scrollbar styling */
/* width and height apply to the scrollbar itself */
::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    height: 8px; /* height of the horizontal scrollbar */
}

/* Track style */
::-webkit-scrollbar-track {
    background: #121212; /* color of the tracking area */
    border-radius: 10px;
}

/* Handle style */
::-webkit-scrollbar-thumb {
    background-color: #f15cfa; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 2px solid #833ab4; /* creates padding around scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #e056e9; /* slightly lighter pink on hover */
}